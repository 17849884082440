import React from 'react';
import './Blog.css';

const Blog = () => {
  return (
    <div>
      <h1>Blog Page</h1>
    </div>
  )
}

export default Blog

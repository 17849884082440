/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {

    const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="navbar">
      <nav>
        <div className="homepage"><a href="./">Krypto</a></div>
        <div className="menu" onClick={()=> {
            setMenuOpen(!menuOpen)
        }} >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={menuOpen ? 'open' : ''}>
          <li>
            <a href="./buynfts">Buy NFTs</a>
          </li>
          <li>
            <a href="./cart">Cart</a>
          </li>
          <li>
            <a href="./sellnfts">Sell NFTs</a>
          </li>
          <li>
            <a href="./blog">Blog</a>
          </li>
          <li>
            <a href="./login">Login</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;

import React from "react";
import "./Feature.css";
import TechCrunch from "./../../Assets/techcrunch.png";
import MIT from "./../../Assets/mit.png";
import FastCompany from "./../../Assets/fastcompany.png";
import Forbes from "./../../Assets/forbes.png";

const Feature = () => {
  return (
    <div className="feature">
      <h3>Featured On:</h3>
      <div className="ftBox">
        <img src={TechCrunch} alt="" />
        <img src={MIT} alt="" />
        <img src={FastCompany} alt="" />
        <img src={Forbes} alt="" />
      </div>
    </div>
  );
};

export default Feature;

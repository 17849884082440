import React from "react";
import "./Analytics.css";
import MenuIcons from "./../../Assets/menus icon.png";
import Smartphone from './../../Assets/smartphone.png';

const Analytics = () => {
  return (
    <div className="analytics">
      <div className="top">
        <div className="top1">
          <img src={MenuIcons} alt="menuIcons" />
          <div className="blur topBlur"></div>
        </div>
        <div className="top2">
          <h1>Built-In Analytics To Track Your NFTs</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis illo
            veritatis facere velit! Accusantium dolore perferendis itaque,
            voluptates, reprehenderit ratione magnam facere mollitia vero
            architecto nostrum nisi debitis optio sequi!
          </p>
          <div className="btnTop">
            <button>Learn More</button>
          </div>
        </div>
      </div>
      <hr id="hrAnal" />
      <div className="bot">
        <div className="bot1">
          <div className="bot1Head">
            <h1>Built-In Analytics To Track Your NFTs</h1>
          </div>
          <div id="bot1Para">
            <div>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est aut
              quas quia unde modi sint incidunt voluptatem ad tenetur, minus
              dolore eos quae! Alias natus ipsam, illo quod nisi sequi voluptate
              mollitia quam consequuntur repudiandae obcaecati necessitatibus
              illum, eum asperiores. Atque ipsam ducimus molestias. Earum
              recusandae blanditiis quidem ullam velit.
            </div>
            <div>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est aut
              quas quia unde modi sint incidunt voluptatem ad tenetur, minus
              dolore eos quae! Alias natus ipsam, illo quod nisi sequi voluptate
              mollitia quam consequuntur repudiandae obcaecati necessitatibus
              illum, eum asperiores. Atque ipsam ducimus molestias. Earum
              recusandae blanditiis quidem ullam velit.
            </div>
          </div>
          <div id="bot1Btn">
            <button>View Our Pricing</button>
          </div>
        </div>
        <div className="bot2">
            <img src={Smartphone} alt="smartphone" />
            <div className="blur bot2Blur"></div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;

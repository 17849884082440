import Lorem from './../assets/image1.jpeg';
import dolor from './../assets/image2.jpg';
import amet from './../assets/image3.jpeg';
import adipisicing from './../assets/image4.jpeg';
import Est from './../assets/image5.jpeg';
import quas from './../assets/image6.jpeg';
import velit from './../assets/image7.jpeg';
import ullam from './../assets/image8.jpeg';
import quidem from './../assets/image9.jpeg';
import blanditiis from './../assets/image10.jpeg';
import recusandae from './../assets/image11.jpeg';
import Earum from './../assets/image12.jpeg';
import molestias from './../assets/image13.jpeg';
import ducimus from './../assets/image14.jpeg';
import Atque from './../assets/image16.jpeg';
import asperiores from './../assets/image17.jpeg';
import necessitatibus from './../assets/image18.jpeg';
import obcaecati from './../assets/image19.jpeg';
import repudiandae from './../assets/image20.jpeg';
import consequuntur from './../assets/image21.jpeg';
import Rashid from './../assets/Rashid.jpeg';
import Omondi from './../assets/omondi.jpeg';

export const BuyList = [
    {
        name: 'Lorem ipsum',
        image: Lorem,
        price: 88.89,
    },
    {
        name: 'dolor sit',
        image: dolor,
        price: 188.89,
    },
    {
        name: 'amet consectetur',
        image: amet,
        price: 288.89,
    },
    {
        name: 'adipisicing elit',
        image: adipisicing,
        price: 388.89,
    },
    {
        name: 'Est aut',
        image: Est,
        price: 488.89,
    },
    {
        name: 'quas quia',
        image: quas,
        price: 588.89,
    },
    
    {
        name: 'Rashid AI',
        image: Rashid,
        price: 588.89,
    },
    {
        name: 'Omondi Robotics',
        image: Omondi,
        price: 588.89,
    },
    {
        name: 'velit',
        image: velit,
        price: 588.89,
    },
    {
        name: 'ullam',
        image: ullam,
        price: 588.89,
    },
    {
        name: 'quidem',
        image: quidem,
        price: 588.89,
    },
    {
        name: 'blanditiis',
        image: blanditiis,
        price: 588.89,
    },
    {
        name: 'recusandae',
        image: recusandae,
        price: 588.89,
    },
    {
        name: 'Earum',
        image: Earum,
        price: 588.89,
    },
    {
        name: 'molestias',
        image: molestias,
        price: 588.89,
    },
    {
        name: 'ducimus',
        image: ducimus,
        price: 588.89,
    },
    {
        name: 'Atque',
        image: Atque,
        price: 588.89,
    },
    {
        name: 'asperiores',
        image: asperiores,
        price: 588.89,
    },
    {
        name: 'necessitatibus',
        image: necessitatibus,
        price: 588.89,
    },
    {
        name: 'obcaecati',
        image: obcaecati,
        price: 588.89,
    },
    {
        name: 'repudiandae',
        image: repudiandae,
        price: 588.89,
    },
    {
        name: 'consequuntur',
        image: consequuntur,
        price: 588.89,
    },
];
import "./App.css";
import Footer from "./Components/Footer/Footer";
import MobileFooter from "./Components/Mobile Footer/MobileFooter";
import Ready from "./Components/Ready/Ready";
import Navbar from "./Navbar/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Blog from "./Pages/Blog/Blog";
import BuyNFTs from "./Pages/BuyNFTs/BuyNFTs";
import SellNFTs from "./Pages/SellNFTs/SellNFTs";
import Home from "./Pages/Home/Home";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/buynfts" exact element={<BuyNFTs />} />
          <Route path="/sellnfts" exact element={<SellNFTs />} />
        </Routes>
        <Ready />
        <Footer />
        <MobileFooter />
      </Router>
    </div>
  );
}

export default App;

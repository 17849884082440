import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <table>
        <thead>
            <tr>
                <th>Krypto</th>
                <th>Market</th>
                <th>Contact</th>
                <th>Join Our Newsletter</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Home</td>
                <td>Buy NFTs</td>
                <td>LinkedIn</td>
                <td>Email:</td>
            </tr>
            <tr>
                <td>About</td>
                <td>Sell NFTs</td>
                <td>Instagram</td>
                <td><input type="email" placeholder='Enter Email...' /></td>
            </tr>
            <tr>
                <td>Contact Us</td>
                <td>Blog</td>
                <td>Twitter</td>
                <td>
                    <button>Submit</button>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Footer

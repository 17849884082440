import React from "react";

function BuyItem({image, name, price}){
    return (
        <div className="buyItem">
            <div style={{backgroundImage:`url(${image})`}}></div>
            <h1>{name}</h1>
            <p>{price}</p>
        </div>
    )
}

export default BuyItem
import React from 'react';
import './Home.css';
import Hero from './../../Components/Hero/Hero';
import Feature from './../../Components/Feature/Feature';
import Analytics from './../../Components/Anaytics/Analytics';
import Testimonials from './../../Components/Testimonials/Testimonials';

const Home = () => {
  return (
    <div className='home'>
      <Hero />
      <Feature />
      <Analytics />
      <Testimonials />
    </div>
  )
}

export default Home

import React, { useState } from "react";
import "./Login.css";

const Login = () => {
  const [loginPage, signUpPage] = useState(true);

  const togglePage = () => {
    signUpPage(!loginPage);
  };

  return (
    <div className="login">
      <div className="blur log2"></div>
      <div className="mainWrapper">
        {loginPage ? (
          <div className="loginPage">
            <div className="heading">
              <h2>Log In</h2>
            </div>
            <div className="form">
              <form action="">
                <div className="forms">
                  <label htmlFor="">Email:</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email..."
                  />
                </div>
                <div className="forms">
                  <label htmlFor="">Password:</label>
                  <input type="password" placeholder="Enter Password" />
                </div>
              </form>
            </div>
            <div className="btn">
              <button>Log In</button>
            </div>
            <div className="noAcc">
              <p>Don't have an Account? </p>
              <button onClick={togglePage}>Sign Up</button>
            </div>
          </div>
        ) : (
          <div className="signUpPage">
            <div className="heading">
              <h2>Sign Up</h2>
            </div>
            <div className="form">
              <form action="">
                <div className="forms">
                  <label htmlFor="">First Name:</label>
                  <input type="text" name="First Name" id="email" />
                </div>
                <div className="forms">
                  <label htmlFor="">Last Name:</label>
                  <input type="text" name="Last Name" id="email" />
                </div>
                <div className="forms">
                  <label htmlFor="">Email:</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Email..."
                  />
                </div>
                <div className="forms">
                  <label htmlFor="">Password:</label>
                  <input type="password" placeholder="Enter Password" />
                </div>
                <div className="forms">
                  <label htmlFor="">
                    Repeat <br /> Password:
                  </label>
                  <input type="password" placeholder="Re-Enter Password" />
                </div>
              </form>
            </div>
            <div className="btn">
              <button>Sign Up</button>
            </div>
            <div className="noAcc">
              <p>Already have an Account? </p>
              <button onClick={togglePage}>Log In</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;

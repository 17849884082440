import React from 'react';
import './SellNFTs.css';

const SellNFTs = () => {
  return (
    <div className='sellNFTs'>
      <h1>Sell NFTs Page</h1>
    </div>
  )
}

export default SellNFTs

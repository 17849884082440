import React from 'react';
import './Hero.css';
import desktop from './../../Assets/desktop.png';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className='hero'>
      <div className='heroL'>
        <div className='heroHeading'>
            <h1>Discover & Collect NFTs</h1>
        </div>
        <div className='heroPara'>
            <h4>The most Secure Marketplace for <br /> Buying and Selling <br /> unique NFTs.</h4>
        </div>
        <div className='heroButtons'>
            <button className='hrBtn1'>Buy NFTs</button>
            <button className='hrBtn2'><Link>Sell NFTs</Link></button>
        </div>
      </div>
      <div className='heroR'>
        <img src={desktop} alt="desktop" />
        <div className="blur heroBlur"></div>
      </div>
    </div>
  )
}

export default Hero

import React from "react";
import "./Testimonials.css";
import Avatar1 from './../../Assets/avatar1.jpeg';
import Avatar2 from './../../Assets/avatar2.jpeg';
import Avatar3 from './../../Assets/avatar3.jpeg';
import Avatar4 from './../../Assets/avatar4.jpeg';
import Avatar5 from './../../Assets/avatar5.jpeg';
import Avatar6 from './../../Assets/avatar6.jpeg';
import Avatar7 from './../../Assets/avatar7.jpeg';
import Avatar8 from './../../Assets/avatar8.jpeg';

const Testimonials = () => {
  return (
    <div className="testimonials">
      <div className="tTop">
        <h3>Read What Others Have To Say:</h3>
      </div>
      <div className="tBot">
        <div className="comments">
          <div className="comImg">
            <img src={Avatar1} alt="Avatar1" />
          </div>
          <div className="comHd">Sarah Michael</div>
          <hr className="commHR"/>
          <div className="comPara">"I've been exploring various NFT marketplaces, but Krypto has truly stood out."</div>
        </div>
        <div className="comments">
          <div className="comImg">
          <img src={Avatar2} alt="Avatar1" />
          </div>
          <div className="comHd">Emily Christopher</div>
          <hr className="commHR"/>
          <div className="comPara">"The user interface is incredibly intuitive and easy to navigate."</div>
        </div>
        <div className="comments">
          <div className="comImg">
          <img src={Avatar3} alt="Avatar1" />
          </div>
          <div className="comHd">Olivia Matthew</div>
          <hr className="commHR"/>
          <div className="comPara"> "The variety of NFTs available is simply mind-blowing."</div>
        </div>
        <div className="comments">
          <div className="comImg">
          <img src={Avatar4} alt="Avatar1" />
          </div>
          <div className="comHd">Ava Jacob</div>
          <hr className="commHR"/>
          <div className="comPara">"I've found some truly unique and rare pieces that I couldn't find anywhere else."</div>
        </div>
        <div className="comments">
          <div className="comImg">
          <img src={Avatar5} alt="Avatar1" />
          </div>
          <div className="comHd">Sophia Ethan</div>
          <hr className="commHR"/>
          <div className="comPara">"The search functionality is top-notch, making it easy to find specific collections or artists."</div>
        </div>
        <div className="comments">
          <div className="comImg">
          <img src={Avatar6} alt="Avatar1" />
          </div>
          <div className="comHd">Isabella Daniel</div>
          <hr className="commHR"/>
          <div className="comPara">"Overall, I highly recommend Krypto to anyone interested in the NFT space."</div>
        </div>
        <div className="comments">
          <div className="comImg">
          <img src={Avatar7} alt="Avatar1" />
          </div>
          <div className="comHd">Emma Noah</div>
          <hr className="commHR"/>
          <div className="comPara">"The marketplace's commitment to security is evident in their robust measures to protect both buyers and sellers."</div>
        </div>
        <div className="comments">
          <div className="comImg">
          <img src={Avatar8} alt="Avatar1" />
          </div>
          <div className="comHd">Mia Liam</div>
          <hr className="commHR"/>
          <div className="comPara"> "The community on Krypto is incredibly supportive and welcoming."</div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

import React from "react";
import "./BuyNFTs.css";
import BuyItem from "./Components/BuyItems";
import { BuyList } from "./Helpers/BuyList";

const BuyNFTs = () => {
  return (
    <div className="buyNFTs">
      <h1>Buy NFTs</h1>
      <div className="buyList">
        {BuyList.map((buyItem, key) => {
          return (
            <BuyItem
              key={key}
              image={buyItem.image}
              name={buyItem.name}
              price={buyItem.price}
            />
          );
        })}
      </div>
    </div>
  )
}

export default BuyNFTs;

import React from 'react';
import './MobileFooter.css';

const MobileFooter = () => {
  return (
    <div className='mobileFooter'>
      <h2>Join Our Newsletter</h2>
      <input type="email" placeholder='Enter your email' />
      <button>Submit</button>
    </div>
  )
}

export default MobileFooter

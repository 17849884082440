import React from 'react';
import './Ready.css';

const Ready = () => {
  return (
    <div className='ready'>
      <div>Are you Ready?</div>
      <div>Be Part Of The Next Big Thing</div>
      <div>
        <button>Get Started</button>
      </div>
    </div>
  )
}

export default Ready
